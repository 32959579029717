import React, { useState } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import "./form.scss"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const formSchema = Yup.object().shape({
  Imie_nazwisko: Yup.string().required("Pole wymagane"),
  Localizacja: Yup.string().required("Pole wymagane"),
  Kiedy: Yup.string().required("Pole wymagane"),
  Jak_dlugo: Yup.string().required("Pole wymagane"),
  Osoby: Yup.string().required("Pole wymagane"),
  Email: Yup.string()
    .email("Adres e-mail jest nieprawidłowy")
    .required("Pole wymagane"),
  Telefon: Yup.string()
    .matches(phoneRegExp, "Numer jest nieprawidłowy")
    .required("Pole wymagane"),
  Dodatkowe_informacje: Yup.string().required("Pole wymagane"),
})

export default () => {
  /* Server State Handling */
  const [serverState, setServerState] = useState()
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: "https://formspree.io/xyybvorz",
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(
          true,
          "Dziękujemy, odpowiemy najszybciej jak to możliwe!"
        )
      })
      .catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }
  return (
    <div>
      <h3>Formularz kontaktu</h3>
      <p>Wpisz dane szukanego lokalu, my zajmiemy się resztą.</p>
      <Formik
        initialValues={{
          Imie_nazwisko: "",
          Jak_dlugo: "",
          Localizacja: "",
          Osoby: "",
          Email: "",
          Kiedy: "",
          Telefon: "",
          Dodatkowe_informacje: "",
        }}
        onSubmit={handleOnSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting }) => (
          <Form id="fs-frm" noValidate>
            <Field
              id="Imie_nazwisko"
              type="text"
              name="Imie_nazwisko"
              placeholder="Imię Nazwisko / Firma"
            />
            <ErrorMessage
              name="Imie_nazwisko"
              className="errorMsg"
              component="p"
            />

            <Field
              id="Localizacja"
              type="text"
              name="Localizacja"
              placeholder="Lokalizacja kwatery (kod pocztowy, miejscowość)"
            />
            <ErrorMessage
              name="Localizacja"
              className="errorMsg"
              component="p"
            />

            <Field id="Osoby" type="text" name="Osoby" placeholder="Ile osób" />
            <ErrorMessage name="Osoby" className="errorMsg" component="p" />

            <Field
              id="Kiedy"
              type="text"
              name="Kiedy"
              placeholder="Od kiedy?"
            />
            <ErrorMessage name="Kiedy" className="errorMsg" component="p" />

            <Field
              id="Jak_dlugo"
              type="text"
              name="Jak_dlugo"
              placeholder="Na jak długo?"
            />
            <ErrorMessage name="Jak_dlugo" className="errorMsg" component="p" />

            <Field
              id="Dodatkowe_informacje"
              name="Dodatkowe_informacje"
              component="textarea"
              placeholder="Dodatkowe informacje"
            />
            <ErrorMessage
              name="Dodatkowe_informacje"
              className="errorMsg"
              component="p"
            />

            <Field id="Email" type="email" name="Email" placeholder="E-mail" />
            <ErrorMessage name="Email" className="errorMsg" component="p" />

            <Field
              id="Telefon"
              type="phone"
              name="Telefon"
              placeholder="Telefon"
            />
            <ErrorMessage name="Telefon" className="errorMsg" component="p" />

            <button type="submit" disabled={isSubmitting}>
              Wyślij
            </button>
            {serverState && (
              <p className={!serverState.ok ? "errorMsg" : ""}>
                {serverState.msg}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
